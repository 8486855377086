import * as _datatables2 from "datatables.net-bs5";

var _datatables = "default" in _datatables2 ? _datatables2.default : _datatables2;

import * as _datatables4 from "datatables.net-buttons";

var _datatables3 = "default" in _datatables4 ? _datatables4.default : _datatables4;

var exports = {};

/*! Bootstrap integration for DataTables' Buttons
 * ©2016 SpryMedia Ltd - datatables.net/license
 */
(function (factory) {
  if (true) {
    // CommonJS
    exports = function (root, $) {
      if (!root) {
        root = window;
      }

      if (!$ || !$.fn.dataTable) {
        $ = _datatables(root, $).$;
      }

      if (!$.fn.dataTable.Buttons) {
        _datatables3(root, $);
      }

      return factory($, root, root.document);
    };
  } else {}
})(function ($, window, document, undefined) {
  'use strict';

  var DataTable = $.fn.dataTable;
  $.extend(true, DataTable.Buttons.defaults, {
    dom: {
      container: {
        className: "dt-buttons btn-group flex-wrap"
      },
      button: {
        className: "btn btn-secondary"
      },
      collection: {
        tag: "div",
        className: "dropdown-menu",
        closeButton: false,
        button: {
          tag: "a",
          className: "dt-button dropdown-item",
          active: "active",
          disabled: "disabled"
        }
      },
      splitWrapper: {
        tag: "div",
        className: "dt-btn-split-wrapper btn-group",
        closeButton: false
      },
      splitDropdown: {
        tag: "button",
        text: "",
        className: "btn btn-secondary dt-btn-split-drop dropdown-toggle dropdown-toggle-split",
        closeButton: false,
        align: "split-left",
        splitAlignClass: "dt-button-split-left"
      },
      splitDropdownButton: {
        tag: "button",
        className: "dt-btn-split-drop-button btn btn-secondary",
        closeButton: false
      }
    },
    buttonCreated: function (config, button) {
      return config.buttons ? $("<div class=\"btn-group\"/>").append(button) : button;
    }
  });
  DataTable.ext.buttons.collection.className += " dropdown-toggle";
  DataTable.ext.buttons.collection.rightAlignClassName = "dropdown-menu-right";
  return DataTable.Buttons;
});

export default exports;